// @flow

import styled from 'styled-components';
import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { Box } from '@latitude/box';
import HowToApply from '@latitude/how-to-apply';
import { ImportantInformation } from '@latitude/important-information';
import { ListNumbered } from '@latitude/list-numbered';
import { Tel } from '@latitude/tel';
import {
  COLOR,
  BREAKPOINT,
  MARGIN,
  ALIGN,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import { Hero } from '@latitude/hero';
import { FeaturesSlider } from '@latitude/features-slider';
import RatesAndFees from '@latitude/rates-and-fees';
import { Strong } from '@latitude/text';
import { StickyCta } from '@latitude/sticky-cta';
import { Link } from '@latitude/link';
import { List, ListItem } from '@latitude/list';
import { Heading4 } from '@latitude/heading';
import { AccordionSidebar } from '@latitude/accordion';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { BrandedCallout } from '@latitude/callout';
import DigitalWallets from '../../../components/DigitalWallets/DigitalWallets';
import Button from '../../../components/Button/Button';
import CalloutBox from '../../../components/CalloutBox/CalloutBox';
import Layout from '../../../components/layout';
import Image from '../../../components/Image/Image';
import PageData from '../../../data/pages/acquired-at-apple.json';
import withStickyState from '../../../hoc/withStickyState';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import Lframe from '../../../components/Lframe/Lframe';
import '../gem-visa-card.scss';
import faqsCredit from '../_faqs';
import { featuresSliderIE11Fix } from '../../../utils/stylesUtils';
import { PageContext } from '../../../context/PageContext';

const applyUrl = 'https://www.apple.com/nz/shop/browse/financing';

type Props = {};
const StyledRatesBox = styled(Box)`
  ul {
    list-style: none;
    padding-left: 20px;
  }
  li {
    position: relative;
    &:before {
      content: '•';
      color: #63b8ff;
      position: absolute;
      left: -22px;
      font-size: 34px;
    }
  }
`;

/*
 * Personal Loan Page class
 */

const GemVisaPage = (props: Props) => {
  const { phoneNumber } = PageData.content;
  const featureSliderItems = [
    {
      icon: 'icon-lrpl',
      title: (
        <>
          6 months interest free
          <sup>*</sup>
        </>
      ),
      text:
        'Spend $250 or more,<sup>*</sup> anywhere Visa is accepted. Use it instore, online and worldwide.'
    },
    {
      icon: 'icon-cardless-branded',
      title: 'Ready, set, tap',
      text:
        'Instant cardless payment feature where you can apply and once approved start shopping within minutes.'
    },
    {
      icon: 'icon-easy',
      title: 'Gem Visa deals',
      text:
        'We\'ve joined with some of New Zealand\'s leading brands so you can enjoy long term interest free deals.<sup>*</sup> Find out more <a class="text-underline" href="/credit-cards/interest-free-offers">here</a>.'
    },
    {
      icon: 'icon-iphone',
      title: 'Stay up to date 24/7',
      text:
        'Download the <a class="text-underline" href="https://www.gemfinance.co.nz/mobile-app/">Latitude App</a> and stay up to date on your balance, transactions and more.'
    }
  ]

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [{
    heading: "What makes Gem Visa different?",
    featureCards: featureSliderItems
  }];
  /** - END - */

  return (
    <Layout hasStickyNav>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer navigation-spacer--hasStickyNav gem-visa-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/credit-cards/gem-visa-card/acquired-at-apple"
            />
            <title>
              0% Interest on Apple Products with Gem Visa | Gem by Latitude
            </title>
            <meta
              name="description"
              content="Buy the Apple products you love when you shop with your Gem Visa at Apple. T&amp;Cs &amp; fees apply."
            />
          </Helmet>

          <Lframe cardLowRate />

          <Hero
            imageContent={
              <div
                id="hero"
                css={`
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    justify-content: flex-end;
                  }
                `}
              >
                <img
                  src={require('../../../images/hero/gem-visa-card-w-illustrations.png')}
                  css={`
                    height: 180px;
                    width: auto;

                    @media (min-width: ${BREAKPOINT.LG}) {
                      height: 281px;
                    }
                  `}
                  alt="Low Rate Card"
                />
              </div>
            }
            title={['Get 0% interest on Apple Products with Gem Visa']}
            text={[
              'Buy the Apple products you love with 0% interest when you shop with your Gem Visa at Apple.* See the latest 0% interest offers at the <a class="text-underline" href="https://www.apple.com/nz/shop/browse/financing" target=”_blank”>Apple website.</a>'
            ]}
            buttonProps={{
              trackId: 'hero-apply-now-at-apple',
              href: 'https://cards.gemvisa.co.nz/',
              children: 'Apply Now'
            }}
            css={`
              a {
                max-width: none;
              }
            `}
            footnote={[
              <sup>*</sup>,
              'Gem Visa T&Cs and fees apply including a $55 establishment fee and $65 annual fee (charged $32.50 half-yearly). Prevailing interest rate (currently 29.49% p.a.) applies to any remaining balance on the expiry of the interest free term. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Further information on rates and fees can be found at gemfinance.co.nz. Available to approved Gem Visa credit card customers only. Credit provided by Latitude Financial Services Ltd.'
            ]}
          />

          <div className="d-lg-none d-xl-none">
            <StickyCta
              href={applyUrl}
              trackId="sticky-cta--apply-now"
              text="Apply now at Apple"
            />
          </div>

          <div className="d-none d-lg-block">
            <StickyNavigation
              items={PageData.nav}
              phoneNumber={PageData.content.phoneNumber}
              ctaHref={applyUrl}
              ctaTarget="_self"
              ctaText="Apply now at Apple"
              isSticky={props.isNavSticky}
              onStickyNavStateChange={props.handleStickyNavStateChange}
              offsetElem="[data-sticky-navigation-offset]"
              trackId="sticky-nav-apply-now-at-apple"
              trackEventData={{
                category: 'button-link',
                action: 'external-link',
                label: 'Apply now at Apple',
                location: 'Sticky Navigation'
              }}
              trackProductId={['CCNZGEM']}
            />
          </div>

          {featureSliderData[0] && (
            <FeaturesSlider
              id="why-us"
              data={featureSliderData[0].featureCards}
              className="pb-0"
              css={featuresSliderIE11Fix}
              heading={featureSliderData[0].heading}
              subheading={featureSliderData[0].description}
              responsiveSettings={PageData.whyChooseLatitudeResponsoveSettings}
            />
          )}

          <RatesAndFees
            id="rates"
            title="Rates, Fees & Conditions Of Use"
            className="rates-list-section"
            ratesListProps={{ data: PageData.ratesList }}
            accordionProps={{
              items: [
                {
                  id: 'account-fees',
                  title: 'Account fees',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Annual Account Fee</strong>
                        </p>
                        <p className="w-100">
                          $65 (charged $32.50 half-yearly)
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Establishment Fee (One Off)</strong>
                        </p>
                        <p className="w-100">$55</p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Paper Statement Fee</strong>
                        </p>
                        <p className="w-100">
                          $1 per statement (avoid this by opting out of paper
                          statements)
                        </p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Replacement Card Fee</strong>
                        </p>
                        <p className="w-100">$5</p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'transaction-fees',
                  title: 'Transaction fees',
                  content: (
                    <StyledRatesBox className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Cash advance fee</strong>
                        </p>
                        <p className="w-100">
                          <ul>
                            <li>$2 - over the counter cash advance</li>
                            <li>$1 - NZ ATM withdrawal</li>
                            <li>$1.50 - International ATM Withdrawal</li>
                            <li>Other ATM fees may apply</li>
                          </ul>
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>International Transaction Fee</strong>
                        </p>
                        <p className="w-100">
                          2% of the purchase or cash advance
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Late fee</strong>
                        </p>
                        <p className="w-100">$15</p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Payment handling fee</strong>
                        </p>
                        <p className="w-100">
                          $2.70 payment handling fee for over the counter
                          payments at NZ Post Shop branches
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Other fees</strong>
                        </p>
                        <p className="w-100">
                          $5 for statement reprints or replacement cards
                        </p>
                      </div>
                    </StyledRatesBox>
                  )
                },
                {
                  id: 'interchange-fees',
                  title: 'Interchange fees',
                  content: (
                    <div className="accordion-custom">
                      <div css="padding: 20px 30px 0 30px">
                        Interchange fees are paid by a business that processes
                        card transactions on behalf of a merchant (acquirer) to
                        the card issuer (Latitude Financial Services Limited in
                        the case of Gem Visa). The interchange fees payable for
                        all Gem Visa domestic Credit Card transactions are set
                        out below.
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry program: Charities</strong>
                        </p>
                        <p className="w-100">0.39%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants Card Present (CP) and Card Not
                            Present (CNP): Rate 1{' '}
                          </strong>
                        </p>
                        <p className="w-100">0.50%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 2
                          </strong>
                        </p>
                        <p className="w-100">0.55%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 3
                          </strong>
                        </p>
                        <p className="w-100">0.60%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 4
                          </strong>
                        </p>
                        <p className="w-100">0.70%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 5
                          </strong>
                        </p>
                        <p className="w-100">0.80%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 6
                          </strong>
                        </p>
                        <p className="w-100">0.98%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry Program: Insurance</strong>
                        </p>
                        <p className="w-100">$1</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry Program: Recurring Payment</strong>
                        </p>
                        <p className="w-100">0.70%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Industry Program: Government, Utilities and Telecom
                          </strong>
                        </p>
                        <p className="w-100">$0.70</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Contactless</strong>
                        </p>
                        <p className="w-100">0.65%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Electronic</strong>
                        </p>
                        <p className="w-100">0.65%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Standard</strong>
                        </p>
                        <p className="w-100">1.25%</p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'conditions',
                  title: 'Gem Visa Conditions of Use',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          These are the Conditions of Use, <br />
                          which govern your Gem Visa credit card
                        </p>
                        <p className="w-100">
                          <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-nz/cou.pdf">
                            <img
                              width="100px"
                              src="/media/gemvisa-cou.png"
                              alt="gemvisa-cou"
                            />
                          </a>
                        </p>
                      </div>
                    </div>
                  )
                }
              ]
            }}
          />

          <DigitalWallets />

          <HowToApply
            id="how-to-apply"
            box1={
              <>
                <Heading4
                  color={COLOR.BLACK}
                  marginBottom={MARGIN.M16}
                  isResponsive={false}
                >
                  How to apply
                </Heading4>
                <ListNumbered
                  data={PageData.content.howToApply}
                  viewStyle="compact"
                  separator="none"
                  counterBgColor={COLOR.BLUE_DEEP}
                />
              </>
            }
            box2={
              <>
                <Heading4
                  color={COLOR.BLACK}
                  marginBottom={MARGIN.M16}
                  isResponsive={false}
                >
                  Eligibility
                </Heading4>
                <Strong
                  css={`
                    display: block;
                    margin-bottom: ${MARGIN.M8};
                  `}
                >
                  You must:
                </Strong>
                <List>
                  {PageData.content.eligibilityCriteria[0].items.map(
                    (item, i) => (
                      <ListItem key={i}>{item}</ListItem>
                    )
                  )}
                </List>
                <Strong
                  css={`
                    display: block;
                    margin-top: ${MARGIN.M16};
                    margin-bottom: ${MARGIN.M8};
                  `}
                >
                  Able to supply:
                </Strong>
                <List>
                  {PageData.content.eligibilityCriteria[1].items.map(
                    (item, i) => (
                      <ListItem key={i}>{item}</ListItem>
                    )
                  )}
                </List>
              </>
            }
          />

          <Box id="faq">
            <Box.Section>
              <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
                Frequently asked questions
              </Heading4>
              <AccordionSidebar data={faqsCredit.gemVisaCardApple} />
            </Box.Section>
          </Box>

          <CalloutBox
            id="creditline"
            title="Are you an existing Gem CreditLine customer?"
            image={
              <Image
                src={require('../../../images/credit-cards/creditline.png')}
                title="Gem CreditLine"
                alt="Gem CreditLine"
              />
            }
            desc={
              <>
                If you have a Gem CreditLine card, and don't want to switch to a
                Gem Visa, don't worry &mdash; your card will still work at over
                2,000 retailers around New Zealand to access long term interest
                free deals.<sup>*</sup>
              </>
            }
            cta={
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Button
                    secondary
                    href="/credit-cards/gem-creditline-card/"
                    trackId="creditline-find-out-more"
                    trackEventData={{
                      category: 'button-link',
                      action: 'internal-link',
                      label: 'Find Out More',
                      location: 'Are you an existing Gem CreditLine customer?'
                    }}
                    trackProductId={['CCNZGEM']}
                  >
                    Find Out More
                  </Button>
                </div>
              </div>
            }
          />

          <BrandedCallout
            line1={
              <>
                <Link
                  variant={COLOR.WHITE}
                  href="https://gemfinance.zendesk.com/hc/en-nz"
                  trackId="link-live-web-chat"
                  trackEventData={{
                    label: 'Live web chat',
                    category: 'text-link',
                    location: "We're here to help"
                  }}
                  trackProductId={['CCNZGEM']}
                >
                  <strong>Live web chat</strong>
                </Link>{' '}
                <span css="display:block; margin-bottom: 16px;">
                  Mon to Fri 8:00am - 6:30pm.
                </span>
                <strong css="display:block;">
                  Call our Gem Visa team on{' '}
                  <Tel no="0800 500 505" color={COLOR.WHITE} />
                </strong>
                <span css="display:block;">Mon to Fri 10:30am - 6:30pm.</span>
              </>
            }
            cta={
              <Box width="210px">
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={applyUrl}
                  trackId="help-banner-apply"
                  className="w-auto"
                  trackEventData={{
                    category: 'cta',
                    action: 'application-link',
                    label: 'Apply now',
                    location: "We're here to help"
                  }}
                  trackProductId={['CCNZGEM']}
                  style={{ marginTop: 24 }}
                >
                  Apply now
                </Link>
              </Box>
            }
          />

          <ImportantInformation
            id="important-information"
            data={require('../../../data/json/disclaimer/acquired-at-apple.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          />
        </Box>
      </main>
    </Layout>
  );
};

const GemVisaPageWithSticky = withStickyState(GemVisaPage);

export default GemVisaPageWithSticky;
